'use client'

import UIButton from '@components/ui/UIButton'
import UIContainer from '@components/ui/UIContainer'
import UIFlex from '@components/ui/UIFlex'
import UILink from '@components/ui/UILink'
import UIText from '@components/ui/UIText'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import { Box } from '@mui/joy'
import color from '@ui/style/color.theme'
import pxToRem from '@utils/pxToRem'
import muiTheme from '@ui/style/muiTheme'
import { useLayoutEffect } from 'react'
import { Icon16ArrowMedium } from 'src/assets/icons/Icon16ArrowMedium'
import IconNotFound from 'src/assets/icons/IconNotFound'
import IconNotFoundSm from 'src/assets/icons/IconNotFoundSm'

export default function NotFound() {
	const { darkMode } = useDarkModeControl()
	useLayoutEffect(() => {
		global.scroll({ top: 0, left: 0 })
	}, [])
	return (
		<Box bgcolor={darkMode ? color.colBlack : color.colWhite}>
			<UIContainer>
				<UIFlex
					align="center"
					justify="center"
					sx={{
						paddingTop: pxToRem(184),
						paddingBottom: pxToRem(184),
						[muiTheme.breakpoints.down('md')]: {
							paddingTop: pxToRem(144),
							paddingBottom: pxToRem(144),
						},
					}}
				>
					<UIFlex
						align="center"
						justify="center"
						sx={{
							'&>*:not(:first-child)': {
								marginTop: pxToRem(12),
							},
						}}
					>
						<Box
							display={{
								xs: 'none',
								md: 'block',
							}}
							sx={{
								width: pxToRem(36),
								height: pxToRem(36),
							}}
						>
							<IconNotFound isDark={darkMode} />
						</Box>
						<Box
							display={{
								xs: 'block',
								md: 'none',
							}}
							sx={{
								width: pxToRem(28),
								height: pxToRem(28),
							}}
						>
							<IconNotFoundSm isDark={darkMode} />
						</Box>
						<UIText
							color={color.colGray5}
							sx={{
								fontWeight: 600,
								fontSize: {
									xs: pxToRem(20),
									md: pxToRem(22),
									lg: pxToRem(24),
								},
								lineHeight: {
									xs: pxToRem(30),
									md: pxToRem(32),
									lg: pxToRem(34),
								},
							}}
						>
							존재하지 않는 페이지입니다.
						</UIText>
					</UIFlex>

					<UIFlex
						mt={{
							xs: pxToRem(24),
							lg: pxToRem(32),
						}}
						sx={{
							flexDirection: {
								xs: 'column',
								md: 'row',
							},
							'&>*:not(:first-child)': {
								marginLeft: {
									xs: pxToRem(12),
									md: pxToRem(16),
								},
							},
						}}
					>
						<UIButton
							variant="outlined"
							color="neutral"
							sx={{
								'&': {
									width: pxToRem(152),
									height: pxToRem(46),
									paddingTop: 0,
									paddingRight: pxToRem(17),
									paddingBottom: 0,
									paddingLeft: pxToRem(23),
									fontWeight: 600,
									fontSize: pxToRem(16),
									lineHeight: pxToRem(18),
									borderRadius: pxToRem(8),
									color: darkMode ? color.colWhite : color.colBlack,
									borderColor: darkMode ? '#444' : color.colGray3,
								},
								'&:active': {
									background: color.colGray1,
								},
								svg: {
									marginLeft: `${pxToRem(4)}!important`,
								},
							}}
							{...{ component: UILink, href: '/', replace: true }}
						>
							<UIText
								color={darkMode ? color.colWhite : color.colBlack}
								whiteSpace="nowrap"
							>
								JTBC 뉴스 홈
							</UIText>
							<Icon16ArrowMedium color={darkMode ? color.colWhite : color.colBlack} />
						</UIButton>
					</UIFlex>
				</UIFlex>
			</UIContainer>
		</Box>
	)
}
