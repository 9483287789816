import { sendGAEvent } from '@next/third-parties/google'

export type GAProps = {
	name: string
	key?: string
	value?: string
}

const handleGAEvent = ({ name, key, value }: GAProps) => {
	const eventParam = key && value ? { [key]: value } : {}
	console.log(name, key, value, eventParam)
	sendGAEvent('event', name, eventParam)
}

export default handleGAEvent
