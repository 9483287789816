const zIndexes = {
	under: -1,
	tooltip: 1000,
	modal: 2000,
	header: 3000,
	fullModal: 4000,
	toast: 9000,
	top: 9999,
} as const

export default zIndexes
