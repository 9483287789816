'use client'

import { forwardRef, useEffect, useMemo, useState } from 'react'
import UITextRoot, { UITextState } from './UITextRoot'
import { theme } from '@ui/style'
import decodeNodeEntity from '@utils/decodeNodeEntity'

export interface UITextProps extends Omit<UITextState, 'ownerState'> {
	ownerState?: UITextState
}

const UIText = forwardRef<HTMLSpanElement, UITextProps>(
	(
		{
			level,
			color = theme.color.colBlack,
			variant,
			weight,
			noWrap = false,
			lineClamp,
			sx,
			readonly,
			ownerState = {},
			children,
			...props
		},
		ref,
	) => {
		const [text, setText] = useState(decodeNodeEntity(children))
		const mergedOwnerState = {
			level: ownerState.level || level,
			weight: ownerState.weight || weight,
			lineClamp: ownerState.lineClamp || lineClamp,
			readonly: ownerState.readonly || readonly,
			color: ownerState.color || color,
			...ownerState,
		}
		useEffect(() => {
			setText(decodeNodeEntity(children))
		}, [children])
		return (
			<UITextRoot
				ref={ref}
				noWrap={noWrap}
				sx={sx}
				ownerState={mergedOwnerState}
				suppressHydrationWarning
				{...props}
			>
				{text}
			</UITextRoot>
		)
	},
)

export default UIText
