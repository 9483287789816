import { decode } from 'html-entities'
import { ReactNode } from 'react'

const detachStringDecodeEntity = (children: ReactNode): ReactNode | string => {
	return typeof children === 'string' ? decode(children as string) : children
}
const decodeNodeEntity = (children: ReactNode): ReactNode | string => {
	return children instanceof Array
		? children.map(detachStringDecodeEntity)
		: detachStringDecodeEntity(children)
}

export default decodeNodeEntity
