import { extendTheme } from '@mui/joy/styles'
import localFont from 'next/font/local'
import { breakpoint } from './mediaQuery.theme'
import pxToRem from '@utils/pxToRem'

export const pretendard = localFont({
	src: '../../assets/fonts/PretendardVariable.woff2',
	adjustFontFallback: false, // prevent NextJS from adding its own fallback font
	display: 'swap',
	weight: '45 920',
})

const muiTheme = extendTheme({
	fontFamily: {
		body: pretendard.style.fontFamily,
		display: pretendard.style.fontFamily,
	},
	breakpoints: {
		values: {
			xs: 0,
			...breakpoint,
		},
	},
	focus: {
		default: {
			outlineOffset: `var(--focus-outline-offset, -2px)`,
			outlineColor: '#000',
		},
	},
})

export { pxToRem }

export default muiTheme
