'use client'

import { styled, Theme } from '@mui/joy/styles'
import type { Property } from 'csstype'
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/joy/Typography'
import { CSSProperties } from 'react'

export type LevelType =
	| 'DisplayXL'
	| 'HeadingXXL'
	| 'HeadingXL'
	| 'HeadingL'
	| 'HeadingM'
	| 'HeadingS'
	| 'Caption'
	| 'CaptionTime'
	| 'TitleXXL'
	| 'TitleXL'
	| 'TitleL'
	| 'TitleM1'
	| 'TitleM2'
	| 'BodyM'
	| 'Footnote'
	| 'ArticleTitle'
	| 'ArticleBodyXL'
	| 'ArticleBodyL'
	| 'ArticleBodyM'
	| 'ArticleBodyMStrong'

export interface UITextState extends Omit<MuiTypographyProps, 'level' | 'color'> {
	lineClamp?: number
	level?: LevelType
	weight?: Property.FontWeight
	color?: Property.Color
	readonly?: boolean
}
interface UITextRootProps {
	theme?: Theme
	ownerState: UITextState
}
const UITextRoot = styled(MuiTypography)<UITextRootProps>(({ theme, ownerState }) => {
	const { level, color, weight, lineClamp, readonly } = ownerState

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const levelStyles = () => {
		let levelStyle
		switch (level) {
			case 'DisplayXL':
				levelStyle = theme?.typo.display.xl
				break
			case 'HeadingXXL':
				levelStyle = theme?.typo.heading.xxl
				break
			case 'HeadingXL':
				levelStyle = theme?.typo.heading.xl
				break
			case 'HeadingL':
				levelStyle = theme?.typo.heading.l
				break
			case 'HeadingM':
				levelStyle = theme?.typo.heading.m
				break
			case 'HeadingS':
				levelStyle = theme?.typo.heading.s
				break
			case 'Caption':
				levelStyle = theme?.typo.caption
				break
			case 'CaptionTime':
				levelStyle = theme?.typo.captionTime
				break
			case 'TitleXXL':
				levelStyle = theme?.typo.title.xxl
				break
			case 'TitleXL':
				levelStyle = theme?.typo.title.xl
				break
			case 'TitleL':
				levelStyle = theme?.typo.title.l
				break
			case 'TitleM1':
				levelStyle = theme?.typo.title.m1
				break
			case 'TitleM2':
				levelStyle = theme?.typo.title.m2
				break
			case 'BodyM':
				levelStyle = theme?.typo.body.m
				break
			case 'Footnote':
				levelStyle = theme?.typo.footnote
				break
			case 'ArticleTitle':
				levelStyle = theme?.typo.article.title
				break
			case 'ArticleBodyM':
				levelStyle = theme?.typo.article.body.m
				break
			case 'ArticleBodyMStrong':
				levelStyle = theme?.typo.article.body.mStrong
				break
			case 'ArticleBodyXL':
				levelStyle = theme?.typo.article.body.xl
				break
			case 'ArticleBodyL':
				levelStyle = theme?.typo.article.body.l
				break

			default:
				break
		}
		return levelStyle && levelStyle
	}

	const weightStyles = () => {
		return weight && { fontWeight: weight }
	}
	const colorStyles = () => {
		return color && { color }
	}

	const readOnlyStyles = () => {
		return (
			readonly && {
				overflow: 'hidden',
				position: 'absolute' as const,
				clip: 'rect(0, 0, 0, 0)',
				clipPath: 'circle(0)',
				width: '1px',
				height: '1px',
				margin: -'1px',
				border: '0',
				padding: '0',
				whiteSpace: 'nowrap',
			}
		)
	}

	const lineClampStyles = () => {
		return (
			lineClamp && {
				display: '-webkit-box',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				WebkitBoxOrient: 'vertical' as const,
				WebkitLineClamp: lineClamp?.toString(),
			}
		)
	}
	const styles = {
		overflowWrap: 'break-word' as const,
		wordBreak: 'break-all' as const,
		...levelStyles(),
		...weightStyles(),
		...colorStyles(),
		...readOnlyStyles(),
		...lineClampStyles(),
	}
	return styles
})

export default UITextRoot
